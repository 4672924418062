// require('offline-plugin/runtime').install();


import './video/kv.mp4';
import './video/video1.mp4';
import './video/video2.mp4';
import './video/video3.mp4';
import './video/video4.mp4';
import './video/video5.mp4';
import './video/video6.mp4';
import './index.html';
import './index.scss';
import './scripts/script.js';




import Vue from 'vue';
import {
  TweenMax,
  Elastic,
  TweenLite
} from 'gsap'
import VueScrollactive from 'vue-scrollactive'
Vue.use(VueScrollactive);
import VueSlider from 'vue-slider-component'
import Tilt from 'vanilla-tilt'
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import {
  ScrollMagicPluginGsap
} from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import anime from 'animejs';
import lax from 'lax.js'
import VueSplitCharacters from 'vue-split-characters';
Vue.use(VueSplitCharacters);



import moment from 'moment';











var app = new Vue({
  el: '#app',
  data() {
    return {
      time: null,
      isActive: false,
      sliderValue: 60,
      styleObject: {
        color: 'red',
        fontSize: '13px',
        clipPath: `polygon(0 0, ${this.sliderValue}% 0, ${this.sliderValue}% 100%, 0% 100%)`
      },
      video: 'v1'
    }
  },
  methods: {
    timer() {
      let my = this
      let startTimestamp = moment().startOf("day");
      this.time = setInterval(() => {
        startTimestamp.add(10, 'milliseconds');
        // console.log(my.time)
        my.time = startTimestamp.format('HH:mm:ss:SS');
      }, 10)
    },
    setTime() {
      this.timer()
    },
    stopTime() {
      if (this.time) {
        clearInterval(this.time)
      }
    },
    open() {
      this.isActive = !this.isActive;
      let tl = new TimelineLite({
        paused: true,
        reversed: true
      });
      tl.staggerTo(".nav li", 0.6, {
          scale: 1,
          y: 0,
          stagger: {
            amount: '.6', //total seconds to divide up among staggers
            from: "top", //or an index value. Determines where staggers originate
            grid: "auto", //or [columns, rows] 
            ease: Linear.easeNone,
          }
        })
        .to(".nav", 0.6, {
          backgroundColor: "rgba(0,0,0,.7)",
          ease: Power1.easeInOut
        }, ".2")
        .to(".nav .socail a", 0.6, {
          scale: 1,
          y: 0,
          ease: Power1.easeInOut
        }, ".4")

      let tl2 = new TimelineLite({
        paused: true,
        reversed: true
      });
      tl2.staggerTo(".nav li", 0.6, {
          scale: 0,
          y: 60,
          stagger: {
            amount: '.6', //total seconds to divide up among staggers
            from: "top", //or an index value. Determines where staggers originate
            grid: "auto", //or [columns, rows] 
            ease: Linear.easeNone,
          }
        })
        .to(".nav", 0.6, {
          backgroundColor: "rgba(0,0,0,0)",
          ease: Power1.easeInOut
        }, ".2")
        .to(".nav .socail a", 0.6, {
          scale: 0,
          y: 60,
          ease: Power1.easeInOut
        }, ".4")

      if (this.isActive === true) {
        tl.play()
      } else {
        tl2.play()
      }
    },
    mouseOver(e) {
      this.video = e;
    }
  },
  components: {
    VueSlider,
    VueScrollactive
    // VueSlider: window['vue-slider-component']
  },
  created() {
    this.timer()

    function s2Time1(panel) {
      var tl = new TimelineLite();
      //tri
      tl.from(panel + " .js-title", .6, {
          opacity: 0,
          top: 100,
          scale: 0.1
        }, "0")
        .from(panel + " .swiper-container", .6, {
          opacity: 0,
          top: 100
        }, ".2")
        .from(panel + " .js-arrow", .6, {
          opacity: 0,
          top: 100,
          scale: 0.1
        }, ".4")
        .from(panel + " .ball", .6, {
          opacity: 0,
          scale: 0.1
          // y: 160
        }, ".6")
      return tl; //very important that the timeline gets returned
    }
  },
  mounted() {



    lax.setup({
      breakpoints: {
        small: 0,
        large: 992
      }
    }) // init

    const updateLax = () => {
      lax.update(window.scrollY)
      window.requestAnimationFrame(updateLax)
    }

    window.requestAnimationFrame(updateLax)





    anime({
      targets: '.kv path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1200,
      delay: function (el, i) {
        return i * 100
      },
      direction: 'alternate',
      loop: false
    });

    let animation = anime({
      targets: '.services path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 800,
      delay: function (el, i) {
        return i * 100
      },
      direction: 'alternate',
      loop: false,
      autoplay: false
    });

    function s1Time1(panel) {
      let tl = new TimelineLite();
      tl.staggerFrom(".nav li", 0.6, {
          scale: 0,
          x: 60,
          stagger: {
            amount: '.6', //total seconds to divide up among staggers
            from: "top", //or an index value. Determines where staggers originate
            grid: "auto", //or [columns, rows] 
            ease: Linear.easeNone,
          }
        })
        .from(".state .drone", .6, {
          opacity: 0,
          y: 200,
          scale: 0.1
        }, ".2")
        .from(panel + " .flex", .6, {
          opacity: 0,
          y: 100,
          scale: 0.1
        }, ".5")
        .from(".logo", .6, {
          opacity: 0,
          y: 100
        }, ".6")
      return tl; //very important that the timeline gets returned
    }

    function s1Time1m(panel) {
      let tl = new TimelineLite();
      tl.from(".state .drone", .6, {
          opacity: 0,
          y: 200,
          scale: 0.1
        }, "0")
        .from(panel + " .flex", .6, {
          opacity: 0,
          y: 100,
          scale: 0.1
        }, ".2")
        .from(".logo", .6, {
          opacity: 0,
          y: 100
        }, ".4")
      return tl; //very important that the timeline gets returned
    }
    if (window.innerWidth < 1281) {
      s1Time1m('.kv')
    } else {
      s1Time1('.kv')
    }







    function s2Time1(panel) {
      let tl = new TimelineLite();
      tl.set(".state .primary", {
        perspective: 400
      });
      tl.staggerFrom(".state .primary span", 0.8, {
          opacity: 0,
          scale: 0,
          y: 80,
          rotationX: 180,
          transformOrigin: "0% 50% -50",
          ease: Back.easeOut
        }, 0.01, "+=0")
        .staggerFrom(".grid__item:nth-child(3) li", 0.6, {
          opacity: 0,
          scale: 0,
          y: 80,
          rotationX: 180,
          ease: Back.easeOut
        }, 0.1, ".2")
        .from(panel + " .controler", .6, {
          opacity: 0,
          y: 100,
          scale: 0.1
        }, ".8")
        .from(panel + " .man img", .6, {
          // opacity: 0,
          scale: 0,
          y: 100,
        }, ".4")
        .from(panel + " .frame", .6, {
          // opacity: 0,
          scale: 0.1
        }, ".6")
        .from(panel + " .count div", .6, {
          opacity: 0,
          y: 100,
        }, ".8")
      return tl; //very important that the timeline gets returned
    }

    function s3Time1(panel) {
      let tl = new TimelineLite();
      tl.staggerFrom(".about .block", 0.6, {
          scale: 0,
          x: 60,
          y: 60,
          backgroundColor: "rgba(0, 0, 0, 1)",
          stagger: {
            amount: '.6', //total seconds to divide up among staggers
            from: "center", //or an index value. Determines where staggers originate
            grid: "auto", //or [columns, rows] 
            ease: Linear.easeNone,
          }
        }).from(panel + " .card", .6, {
          opacity: 0,
          y: 100
        }, ".8")
        .from(panel + " .arrow ", .6, {
          scale: 0,
          y: 100
        }, "1.2")
      return tl; //very important that the timeline gets returned
    }


    function s4Time1(panel) {
      let tl = new TimelineLite();
      tl.set(".tilt", {
        overflow: 'hidden'
      });
      tl.set(panel + " h3", {
        perspective: 400
      });
      tl.set(panel + " h3 span", {
        display: 'inline-flex'
      });
      tl.staggerFrom(panel + " h3 span", 0.6, {
          opacity: 0,
          scale: 0,
          y: 80,
          rotationX: 180,
          transformOrigin: "0% 50% -50",
          ease: Back.easeOut
        }, 0.01, "+=0")
        .from(panel + " .light", .6, {
          opacity: 0,
          y: 100,
        }, ".6")
        .from(panel + " .line", .6, {
          opacity: 0,
          scaleY: 0,
        }, ".6")
        .from(panel + " .des p", .6, {
          opacity: 0,
          y: 100,
        }, ".4")
        .from(panel + " .is-overlay", .4, {
          right: 100,
        }, "0")
        .to(panel + " .is-overlay", .6, {
          scaleX: 0,
        }, ".6")
        .from(panel + " .tilt .relative", .6, {
          opacity: 0,
          scale: 1.3,
          onCompleteScope: tl.set(".tilt", {
            overflow: 'visible'
          })
        }, ".6")
        .from(panel + " .tilt .absolute", .6, {
          opacity: 0,
          y: -16,
          x: -16
        }, "1")
      return tl; //very important that the timeline gets returned
    }

    function s5Time1(panel) {
      let tl = new TimelineLite();
      tl.from(panel + " .bg", .6, {
          opacity: 0,
          x: -100
        }, "0")
        .from(panel + " .title span", .6, {
          opacity: 0,
          bottom: -100
        }, "0")
        .from(".services  .drone", .6, {
          opacity: 0,
          scale: 0,
          y: 100
        }, ".4")
        .from(panel + " .title img", .6, {
          opacity: 0,
          scaleX: 0,
        }, ".2")
        .from(panel + " .title .f4", .6, {
          opacity: 0,
          y: 100
        }, ".4")
        .from(panel + " .slider > img", .6, {
          opacity: 0,
          x: -100
        }, ".6")
        .from(panel + " .slider input", .6, {
          opacity: 0,
          y: 100
        }, "1")
        .from(panel + " .slider .p1", .6, {
          opacity: 0,
          x: -100
        }, ".6")
        .from(panel + " .container > p", .6, {
          opacity: 0,
          y: 100
        }, "1.2")
        .from(panel + " .lens", .6, {
          opacity: 0,
          y: 100
        }, "1.4")

      return tl; //very important that the timeline gets returned
    }


    function s6Time1(panel) {
      let tl = new TimelineLite();
      tl.set(".production .secondary .relative", {
        perspective: 400
      });

      tl.set(".secondary .relative span", {
        display: "inline-block"
      });

      tl.staggerFrom(".secondary .relative span", 0.8, {
          opacity: 0,
          scale: 0,
          y: 80,
          rotationX: 180,
          transformOrigin: "0% 50% -50",
          ease: Back.easeOut
        }, 0.01, "+=0")
        .staggerFrom(panel + " .list li", 0.6, {
          opacity: 0,
          scale: 0,
          y: 80,
          rotationX: 180,
          ease: Back.easeOut
        }, 0.1, ".2")
        .from(panel + " .relative  img", .6, {
          scaleX: 0,
        }, ".2")
        .from(panel + " .grid div > img", .6, {
          opacity: 0,
          y: 100,
        }, ".4")

      return tl; //very important that the timeline gets returned
    }

    function s7Time1(panel) {
      let tl = new TimelineLite();
      tl.set(".works .grid", {
        perspective: 400
      });
      tl.staggerFrom(".works .grid a", 0.8, {
          opacity: 0,
          scale: 0,
          y: 80,
          // rotationX: 180,
          stagger: {
            amount: '.2', //total seconds to divide up among staggers
            from: "center", //or an index value. Determines where staggers originate
            grid: "auto", //or [columns, rows] 
            ease: Linear.easeNone,
          },
          transformOrigin: "center",
          ease: Back.easeOut
        }, 0.2, ".2")
        .from(panel + " .title", .6, {
          opacity: 0,
          y: 100,
          scale: 0
        }, "0")
        .from(panel + " .btn-more", .6, {
          opacity: 0,
          y: 100,
          scale: 0
        }, ".4")
      return tl; //very important that the timeline gets returned
    }


    function s8Time1(panel) {
      let tl = new TimelineLite();
      tl.from(panel + " .container", .6, {
          opacity: 0,
          y: 100,
          scale: 0
        }, "0")
        .from(panel + " .title", .6, {
          opacity: 0,
          x: 100,
        }, ".2")
        .from(panel + " .subtitle", .6, {
          opacity: 0,
          x: 100,
        }, ".4")
        .from(panel + " ul", .6, {
          opacity: 0,
          x: 100,
        }, ".6")
        .from(panel + " .map", .6, {
          opacity: 0,
          y: 100,
          scale: 0
        }, ".4")
      return tl; //very important that the timeline gets returned
    }


    // init controller
    const controller = new ScrollMagic.Controller();

    let s2 = new ScrollMagic.Scene({
        triggerElement: "#equipment",
      })
      .setTween(s2Time1(".state"))
      .addTo(controller);

    let s3 = new ScrollMagic.Scene({
        triggerElement: "#about",
      })
      .setTween(s3Time1(".about"))
      .addTo(controller);

    let s4 = new ScrollMagic.Scene({
        triggerElement: "#service",
      })
      .setTween(s4Time1(".services"))
      .on("enter", function () {
        let animation = anime({
          targets: '.services path',
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 800,
          delay: function (el, i) {
            return i * 100
          },
          direction: 'alternate',
          loop: false,
        });
      })
      .on("leave", function () {
        let animation = anime({
          targets: '.services path',
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 800,
          delay: function (el, i) {
            return i * 100
          },
          direction: 'alternate',
          loop: false
        });
      })
      .addTo(controller);

    let s5 = new ScrollMagic.Scene({
        triggerElement: "#show",
      })
      .setTween(s5Time1(".services-show"))
      .addTo(controller);

    let s6 = new ScrollMagic.Scene({
        triggerElement: "#post",
      })
      .setTween(s6Time1(".production"))
      .addTo(controller);


    let s7 = new ScrollMagic.Scene({
        triggerElement: "#video",
      })
      .setTween(s7Time1(".works"))
      .addTo(controller);

    let s8 = new ScrollMagic.Scene({
        triggerElement: "#contact",
      })
      .setTween(s8Time1(".contact"))
      .addTo(controller);







    
    VanillaTilt.init(document.querySelectorAll(".tilt"), {
      speed: 1300
    })
  }
})